import React, { FC } from 'react';
import { IArticleCard } from 'gatsby-theme-husky/src/components/ArticleCard/models';
import ArticleCardControl from './ArticleCardControl';
import ArticleCardLink from './ArticleCardLink';
import ArticleCardText from './ArticleCardText';
import './ArticleCard.scss';
import './ArticleCardExtends.scss';

const ArticleCard: FC<IArticleCard> = ({
  article: {
    properties: { title, articleHeroBannerMainImageAltText, tags, articleBody, localArticleImage },
    url,
    localImage,
  },
  readMoreText,
  ariaLabel,
  showTags = false,
  sliceTitle,
}) => {
  const image = localImage || localArticleImage;

  return (
    <div className="article-card">
      <ArticleCardLink {...{ url, localImage: image, articleHeroBannerMainImageAltText }} />
      <ArticleCardText {...{ title, url, articleBody, sliceTitle }} />
      <ArticleCardControl {...{ showTags, tags, ariaLabel, readMoreText, url }} />
    </div>
  );
};

export default ArticleCard;
