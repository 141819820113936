import { Link } from 'gatsby';
import React from 'react';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';

const ArticleCartLink = ({ url, localImage, articleHeroBannerMainImageAltText }) => {
  return (
    <>
      <Link to={url} className="article-card__link">
        {localImage ? (
          <GatsbyImage
            isLazyLoading
            wrapperClasses="article-card__image"
            fluid={localImage?.childImageSharp.fluid}
            alt={articleHeroBannerMainImageAltText}
          />
        ) : null}
      </Link>
    </>
  );
};

export default ArticleCartLink;
