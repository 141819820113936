import React, { FC } from 'react';
import HeroBanner from 'components/HeroBanner';
import { ArticleListingHeroBannerProps } from './models';
import './ArticleListingHeroBanner.scss';
import './ArticleListingHeroBannerExtends.scss';
import './ArticleListingHeroBannerRTL.scss';

const ArticleListingHeroBanner: FC<ArticleListingHeroBannerProps> = ({
  className,
  ...restProps
}) => <HeroBanner className={className} {...restProps} />;

export default ArticleListingHeroBanner;
