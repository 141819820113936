import React from 'react';
import TagsList from 'gatsby-theme-husky/src/components/TagsList';
import Button from 'gatsby-theme-husky/src/common/Button';

const ArticleCardControl = ({ showTags, tags, ariaLabel, readMoreText, url }) => (
  <div className="article-card__controls">
    {showTags ? <TagsList dark tags={tags} /> : null}
    {readMoreText && ariaLabel ? (
      <Button ariaLabel={ariaLabel} btnColorVariant="outline-dark" link={url}>
        {readMoreText}
      </Button>
    ) : null}
  </div>
);

export default ArticleCardControl;
