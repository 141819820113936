import React, { FC, ReactElement } from 'react';
import { IBodyStructureElement, IPropsBodyRenderer } from './model';

const BodyRenderer: FC<IPropsBodyRenderer> = (props): ReactElement => {
  const { bodyData, bodyStructure, bodyItemProps } = props;

  return (
    <>
      {bodyData?.length > 0
        ? bodyData.map((bodyItem: IBodyStructureElement, index: number) => {
            const keyId = `${bodyItem.structure}_${index}`;

            return bodyStructure[bodyItem.structure]
              ? bodyStructure[bodyItem.structure](
                  {
                    properties: {
                      ...bodyItem.properties,
                      ...bodyItemProps,
                      svg: bodyItem.svg,
                    },
                    structure: bodyItem.structure,
                  },
                  keyId
                )
              : null;
          })
        : null}
    </>
  );
};

export default BodyRenderer;
