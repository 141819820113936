import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { ITagsList } from './models';
import './TagsList.scss';

const TagsList: FC<ITagsList> = ({ tags, dark = false }) => (
  <div className="tags">
    {tags?.map((tag) => {
      return tag?.url[0]?.url ? (
        <Link key={tag.name} className={classNames('tags__item', { dark })} to={tag.url[0].url}>
          {tag.url[0].name}
        </Link>
      ) : null;
    })}
  </div>
);

export default TagsList;
